import styled from 'styled-components'
import selectedBg from 'assets/img/selected.svg'

type CardProps = {
  $color: string
}

export const CardBox = styled.div<CardProps>`
position: relative;
display: flex;
gap: 2em;
align-items: center;


.image {
  width: auto;
  height: 120px;
}
.text {
  flex: 1;
  font-family: 'ayuthaya';
  font-size: 20px;
  line-height: 120%;
  
  span {
    background-image: url(${selectedBg});
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
  }
}
.number {
  position: relative;
  span {
    font-family: 'Baloo';
    color: #fff;
    font-size: 70px;
    position: absolute;
    left: 0;
    bottom: -0.1em;
    z-index: 10;
  }
  svg {
    position: relative;
    width: 100px;
    height: auto;
    color: ${({ $color }) => $color};
  }
}


@media (max-width:900px) {
  flex-wrap: wrap;
  .image {
    order: 1;
  }
  .number {
    order: 2;
    position: absolute;
    top: 70px;
    left: 90px;
    
    
    span {
      font-size: 30px;
    }
    svg {
      width: 50px;
      height: 50px;
    }
  }
  .text {
    flex: 1;
    min-width: 100%;
    order: 3;
  }
}

`




