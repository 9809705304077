import * as s from './Styles';
import Button from 'components/Button/Button';
import { ReactComponent as Download } from 'assets/img/download.svg';
import Accordeon from 'components/Accordeon/Accordeon';
import { accordeonItem, helpDataType, useFetchData } from 'api/api';
import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import transformImageUri from 'utils/transformImageUri';

const Help = () => {
  const helpData = useFetchData({
    path: 'help-page-content',
  }) as helpDataType;

  const {
    title,
    description,
    faqTitle,
    faqDescription,
    videos,
    instructionsDownloads,
    faqList,
  } = helpData || {};

  const [faqItems, setFaqItems] = useState<accordeonItem[]>([]);

  useEffect(() => {
    if (faqList?.length) {
      let arr: accordeonItem[] = [];

      faqList.forEach((x) => {
        arr.push({
          active: false,
          title: x.title,
          content: (
            <ReactMarkdown
              transformImageUri={transformImageUri}
              children={x.content || ''}
            />
          ),
        });
      });

      setFaqItems(arr);
    }
  }, [faqList]);

  return helpData ? (
    <>
      <div className='wrap'>
        <h1>
          {title}
          <small>{description}</small>
        </h1>

        <s.VideoGrid>
          {videos.map((x, i) => {
            return (
              <s.VideoBox key={i}>
                <iframe
                  width='560'
                  height='315'
                  src={x?.videoUrl}
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                />
              </s.VideoBox>
            );
          })}
        </s.VideoGrid>

        <h2>
          {faqTitle}
          <small>{faqDescription}</small>
        </h2>

        {instructionsDownloads.map((x, i) => {
          return (
            <a
              key={i}
              href={x.buttonLink}
              style={{ display: 'inline-block', marginBottom: '3em' }}>
              <Button
                style={{ background: '#D9F2EE' }}
                icon={<Download />}
                text={x.buttonText}
              />
            </a>
          );
        })}

        <hr />
        <Accordeon data={faqItems} />
      </div>
    </>
  ) : null;
};

export default Help;
