import styled from 'styled-components'
import footerBg from 'assets/img/footer.jpg'




export const FooterBox = styled.div`
position: relative;
z-index: 10;
margin-top: 7vh;
background-color: #F5F6FA;
background-image: url(${footerBg});
background-position: 50% 0;
background-repeat: no-repeat;
background-size: auto 700px;
min-height: 60vh;

.wrap {
  padding-top: 50px;
  padding-left: 23%;
  text-align: right;
}

.bigText {
  font-family: 'Baloo';
  font-size: 30px;
  margin-bottom: 0.5em;
}

.footerSun {
  position: absolute;
  left: 10%;
  top: -10%;
  height: auto;
  width: 150px;
}

.bird {
  position: absolute;
  right: 5%;
  bottom: 100%;
}
`

