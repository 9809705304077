import * as s from './Styles';

import Button from 'components/Button/Button';
import 'animate.css/animate.min.css';

import BuyCard from 'components/BuyCard/BuyCard';
import { shopDataType, useFetchData } from 'api/api';
import ReactMarkdown from 'react-markdown';
import transformImageUri from 'utils/transformImageUri';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const Delivery = () => {
  const shopPageData = useFetchData({
    path: 'shop-page-content',
  }) as shopDataType;

  const { title, description, pageTextContent, shopList } = shopPageData || {};

  return shopPageData ? (
    <>
      <div className='wrap'>
        <s.Title as='h1'>
          {title}
          <small>{description}</small>
        </s.Title>
        <s.CardArea style={{ marginBottom: '5vh' }}>
          <ResponsiveMasonry columnsCountBreakPoints={{ 860: 1, 861: 2 }}>
            <Masonry gutter='3em'>
              {shopList.map((x, i) => {
                let fiatListData: any[] = [];

                x.shopFeatList.forEach((item) => {
                  fiatListData.push({
                    text: item.featText,
                    iconColor: x.hasNanny ? '#85CCDE' : '#9aab58',
                  });
                });

                let buttonsData: any[] = [];

                x.shopButtons.forEach((button, i) => {
                  buttonsData.push(
                    <a
                      key={i}
                      target='_blank'
                      href={button.link}                      
                      rel='noreferrer'>
                      <Button
                        icon={
                          <img
                            width={35}
                            src={transformImageUri(button.icon?.url)}
                            alt={button.text}
                          />
                        }
                        text={button.text}
                        style={{
                          background: button.backgroundColor,
                          color: '#fff',
                          textShadow: 'none',
                        }}
                      />
                    </a>
                  );
                });

                return (
                  <BuyCard
                    hasNanny={x.hasNanny}
                    hasNannyText={x.hasNannyText}
                    key={i}
                    image={transformImageUri(x.image?.url)}
                    title={x.title}
                    listContent={fiatListData}
                    buttons={buttonsData}
                    slug={x.Slug}
                  />
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </s.CardArea>
        <div style={{ clear: 'both' }}></div>
        <s.Text>
          <ReactMarkdown
            transformImageUri={transformImageUri}
            children={pageTextContent || ''}
          />
        </s.Text>
      </div>
    </>
  ) : null;
};

export default Delivery;
