import { useFetchData, mainPageDataType } from 'api/api';
import { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';

const AppDownload = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const mainPageData = useFetchData({
    path: 'main-page-content',
  }) as mainPageDataType;

  const {
    appInfoBox: {
      iosStoreLink,
      iosStoreLinkNanny,
      androidStoreLink,
      androidStoreLinkNanny,
    },
  } = mainPageData || {};

  useEffect(() => {
    const isNannyLink = !location.pathname.includes('nonanny');

    if (isAndroid) {
      window.location.href = isNannyLink
        ? androidStoreLinkNanny
        : androidStoreLink;
    } else if (isIOS) {
      window.location.href = isNannyLink ? iosStoreLinkNanny : iosStoreLink;
    } else {
      navigate('/#AppInfoBox');
    }
  }, [
    androidStoreLink,
    androidStoreLinkNanny,
    iosStoreLink,
    iosStoreLinkNanny,
    location,
  ]);
  return null;
};

export default AppDownload;
