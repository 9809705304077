import styled from 'styled-components';

export const Title = styled.div`
  max-width: 100% !important;
  text-align: center;
  margin-bottom: 1.5em !important;
`;

export const CardArea = styled.div`
  position: relative;
  justify-items: center;
  margin-bottom: 6vh;
  margin: 0 auto;

  & > * {
    position: relative;
    width: 100%;
  }
`;

export const Text = styled.div`
  text-align: center;
  margin-top: 10vh;
`;
