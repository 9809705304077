import React, { Suspense, useEffect, useState } from 'react';
import UnderScroll from './UnderScroll';
const CompareToys = React.lazy(
  () => import('components/CompareToys/CompareToys')
);

const MainPage = () => {
  const [showUi, setShowUi] = useState(false);

  const [showUnderScroll, setShowUnderScroll] = useState(false);

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', () => setShowUnderScroll(true));
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', () => setShowUnderScroll(false));
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowUi(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return showUi ? (
    <>
      <Suspense fallback={<div></div>}>
        <div className='wrap'>
          <CompareToys />
        </div>
      </Suspense>
      {showUnderScroll && (
        <Suspense fallback={<div></div>}>
          <UnderScroll />
        </Suspense>
      )}
    </>
  ) : null;
};

export default MainPage;
