import styled from 'styled-components';

export const ListBox = styled.div`
  position: relative;
  ul {
    font-size: 1.3em;
    list-style-type: none;
    text-align: left;
    list-style-type: none !important;
    li {
      position: relative;
      padding-left: 45px;
      padding-bottom: 0.5em;

      span {
        position: relative;
        &.overline {
          text-decoration: line-through;
        }
      }

      svg {
        position: absolute;
        left: 0;
        top: -7px;
        width: 35px;
        min-width: 35px;
        height: auto;
        color: ${({ theme }) => theme.colors.sea};
      }
    }
  }
`;
