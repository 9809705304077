import styled, { css } from 'styled-components'


export const Head = styled.div`
position: relative;
font-family:'Baloo';
padding: 1em;
font-size: 1.5rem;
background: rgba(209, 225, 146, 0.3);
border-radius: 9% 52% 55% 23% / 100% 62% 100% 55% ;
cursor: pointer;
transition: all 200ms;
margin-top: 1em;

&:hover {
  text-decoration: underline;
}
`

export const Content = styled.div`
position: relative;
padding: 1em;
display: none;
`

export const Item = styled.div<{ isActive: boolean }>`
position: relative;

${({ isActive, theme }) => isActive && css`
  ${Head} {
    background-color: ${theme.colors.orange};
    color: #fff;
  }
  ${Content} {
    display: block;
  }
`}



`