import 'animate.css/animate.min.css';
import { useFetchData, warrantyDataType } from 'api/api';
import ReactMarkdown from 'react-markdown';
import transformImageUri from 'utils/transformImageUri';

const Warranty = () => {
  const warrantyData = useFetchData({
    path: 'warranty-page-content',
  }) as warrantyDataType;

  return warrantyData ? (
    <>
      <div className='wrap'>
        <h1 id='warranty'>
          {warrantyData.title}
          <small> {warrantyData.description}</small>
        </h1>
        <ReactMarkdown
          transformImageUri={transformImageUri}
          children={warrantyData.pageTextContent || ''}
        />
      </div>
    </>
  ) : null;
};

export default Warranty;
