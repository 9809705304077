import React from 'react';
import * as s from './Styles';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import { ReactComponent as Cart } from 'assets/img/cart.svg';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';
import VideoCard from 'components/VideoCard/VideoCard';
import StairsCard from 'components/StairsCard/StairsCard';
import { ModalContext } from 'App';
import { ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import 'animate.css/animate.min.css';
// import CompareToys from 'components/CompareToys/CompareToys';
import {
  featItem,
  mainPageDataType,
  mainPageVideoItem,
  stairItem,
  useFetchData,
} from 'api/api';
// import BigDealBox from 'components/BigDealBox/BigDealBox';
// import AppInfo from 'components/AppInfo/AppInfo';
const BigDealBox = React.lazy(() => import('components/BigDealBox/BigDealBox'));
const AppInfo = React.lazy(() => import('components/AppInfo/AppInfo'));

function RenderCards({ data }: { data: featItem[] | undefined }) {
  const navigate = useNavigate();
  return (
    <>
      {data?.map((item) => {
        let content: string | ReactNode = item.description;

        if (item.isGreen) {
          content = (
            <Button
              onClick={() => navigate(item.buttonLink)}
              style={{ background: '#fff' }}
              icon={<Cart />}
              text={item.description}
            />
          );
        }

        return (
          <AnimationOnScroll
            key={item.title}
            initiallyVisible
            animateOnce
            animateIn='animate__bounceIn'>
            <Card
              green={item.isGreen}
              iconImg={process.env.REACT_APP_APIHOST + item?.icon?.url}
              text={content}
              title={item.title}
            />
          </AnimationOnScroll>
        );
      })}
    </>
  );
}

function RenderStairs({ data }: { data: stairItem[] | undefined }) {
  return (
    <>
      {data?.map((item, i) => (
        <AnimationOnScroll
          key={i}
          animateOnce
          animateIn='animate__fadeInLeft'>
          <StairsCard
            color={item.bgColor}
            text={item.text}
            number={'0' + ++i}
            iconImage={process.env.REACT_APP_APIHOST + item?.icon?.url}
          />
        </AnimationOnScroll>
      ))}
    </>
  );
}

function RenderPosters({ data }: { data: mainPageVideoItem[] | undefined }) {
  const modalControl = useContext(ModalContext);

  function openVideoInModal(innerContent: JSX.Element) {
    const data =
      {
        title: 'MobileMenu',
        body: <s.VideoModal>{innerContent}</s.VideoModal>,
      } || {};
    modalControl?.update(data);
  }

  return (
    <>
      {data?.map((item) => (
        <AnimationOnScroll
          key={item.title}
          animateOnce
          animateIn='animate__flipInX'>
          <VideoCard
            onClick={() =>
              openVideoInModal(
                <iframe
                  width='560'
                  height='315'
                  src={item.videoUrl}
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                />
              )
            }
            videoPoster={process.env.REACT_APP_APIHOST + item?.poster?.url}
            title={item.title}
            beforeTitle={item.beforeTitle}
            afterTitle={item.afterTitle}
          />
        </AnimationOnScroll>
      ))}
    </>
  );
}

const UnderScroll = () => {
  const mainPageData = useFetchData({
    path: 'main-page-content',
  }) as mainPageDataType;

  const { features, videoGallery, stairsFeatures, bigDealBox, appInfoBox } =
    mainPageData || {};

  return (
    <>
      <div className='wrap'>
        <hr />
        <h2>
          {features?.title}
          <small>{features?.description}</small>
        </h2>
        <s.CardArea>
          <RenderCards data={features?.featureItem} />
        </s.CardArea>
      </div>
      <s.VideoBox>
        <div className='wrap'>
          <h2>
            {videoGallery?.title}
            <small>{videoGallery?.description}</small>
          </h2>
          <s.VideoGrid>
            <RenderPosters data={videoGallery?.videoItems} />
          </s.VideoGrid>
        </div>
      </s.VideoBox>
      <div className='wrap'>
        <h2>
          {stairsFeatures?.title}
          <small>{stairsFeatures?.description}</small>
        </h2>
        <s.StairsBox>
          <RenderStairs data={stairsFeatures?.stairsItems} />
        </s.StairsBox>
      </div>
      {bigDealBox && <BigDealBox data={bigDealBox} />}
      {appInfoBox && <AppInfo data={appInfoBox} />}
    </>
  );
};

export default UnderScroll;
