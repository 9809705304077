import styled from 'styled-components';

export const Useful = styled.div`

    .pagination-cont
    {
        display: flex;
        justify-content: space-between;
    }

    .page-item, .prev-page-item, .next-page-item {
        &.active {
            background: rgb(241, 246, 222);
        }

        position: relative;
        border: 0px;
        font-size: 20px;
        font-family: inherit;
        font-weight: bold;
        cursor: pointer;
        backdrop-filter: blur(10px);
        background: rgba(220, 240, 141, 0.8);
        border-radius: 50px;
        padding: 0.5em 1.5em;
        text-shadow: rgba(255, 255, 255, 0.5) 1px 1px 0px;
        display: flex
        align-items: center;
        gap: 0.3em;
        transition: transform 200ms;
        cursor: pointer;
        
    }

    .page-item:hover{
        span{
            position: relative;
            &::after  {
                transform: scaleX(1);
            }
        }
    }

    .page-item,
    .prev-page-item,
    .next-page-item {          
        span {  
            position:relative;
            &:after{
                content: "";
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: 100%;
                height: 2px;
                background-color: rgb(61, 69, 79);
                border-radius: 2px;
                transform: scaleX(0);
                transform-origin: 0px 50%;
                transition: transform 400ms
            }
        }
    }
    

`