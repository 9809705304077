import isEqual from 'lodash.isequal';
import path from 'path';
import { useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';

const baseUrl = process.env.REACT_APP_APIHOST + '/api/';

export type accordeonItem = {
  active: boolean;
  title: string;
  content: any;
};

type pathType =
  | 'header-footer-content'
  | 'warranty-page-content'
  | 'help-page-content'
  | 'library-page-content'
  | 'compare'
  | 'shop-page-content'
  | 'useful-page-content'
  | 'main-page-content';

export type menuItemType = {
  label: string;
  link: string;
  icon: {
    url: string;
  };
};
export type typingItemType = {
  phrase: string;
};

export type footerContentType = {
  whatsAppLink: string;
  contactEmail: string;
  phone: string;
  workingHours: string;
  description: string;
};

export type footerHeaderType = {
  orderButton: string;
  LinkUrl: string;
  menu: menuItemType[];
  typings: typingItemType[];
  videoButton: {
    bigText: string;
    smallText: string;
  };
  VideoLink: string;
  footerContent: footerContentType;
};

export type warrantyDataType = {
  title: string;
  description: string;
  pageTextContent: string;
};

type colorBubbleType = {
  title: string;
  color: 'red' | 'green';
  bubbleText: string;
  icon: {
    url: string;
  };
};

type libraryDownloadEl = {
  title: string;
  content: string;
};

export type libraryDataType = {
  title: string;
  description: string;
  pageTextContent: string;
  coloredTextBubble: colorBubbleType[];
  downloadSection: {
    title: string;
    description: string;
    downloadElements: libraryDownloadEl[];
  };
  instructionSection: {
    title: string;
    buttonText: string;
    buttonLink: string;
  };
};

type helpVideo = {
  videoUrl: string;
};

type helpDownloadbtn = {
  buttonText: string;
  buttonLink: string;
};

type helpFaqItem = {
  title: string;
  content: string;
};

export type helpDataType = {
  title: string;
  description: string;
  faqTitle: string;
  faqDescription: string;
  videos: helpVideo[];
  instructionsDownloads: helpDownloadbtn[];
  faqList: helpFaqItem[];
};

export type featItem = {
  title: string;
  description: string;
  isGreen: boolean;
  buttonLink: string;
  icon: {
    url: string;
  };
};

export type mainPageVideoItem = {
  beforeTitle: string;
  afterTitle: string;
  title: string;
  videoUrl: string;
  poster: {
    url: string;
  };
};

export type stairItem = {
  text: string;
  bgColor: string;
  icon: {
    url: string;
  };
};

type appfeatureItem = {
  feature: string;
};

export type appInfoBox = {
  title: string;
  description: string;
  iosStoreLink: string;
  iosStoreLinkNanny: string;
  androidStoreLink: string;
  androidStoreLinkNanny: string;
  appBoxTitle: string;
  appBoxTitleNanny: string;
  text: string;
  appFeaturesList: appfeatureItem[];
  appFeaturesListNanny: appfeatureItem[];
  appPhoto: {
    url: string;
  };
  appPhotoNanny: {
    url: string;
  };
};

export type mainPageDataType = {
  features: {
    title: string;
    description: string;
    featureItem: featItem[];
  };
  videoGallery: {
    title: string;
    description: string;
    videoItems: mainPageVideoItem[];
  };
  stairsFeatures: {
    title: string;
    description: string;
    stairsItems: stairItem[];
  };
  bigDealBox: {
    title: string;
    buttonText: string;
  };
  appInfoBox: appInfoBox;
};

type compareListItem = {
  text: string;
  isStrike: boolean;
};

export type compareVidoItem = {
  link: string;
  poster: {
    url: string;
  };
};

export type compareSectionDescriptionText = {
  text: string;
  type: string;
  url: string;
  children: compareSectionDescriptionText[];
};

export type compareSectionDescription = {
  children: compareSectionDescriptionText[];
  type: string;
  url: string;
  level: number;
};

export type compareCardItemImageFormat = {
  ext: string;
  url: string;
  width: number;
  height: number;
}

export type compareCardItemImageFormatTypes = {
  large: compareCardItemImageFormat;
  small: compareCardItemImageFormat;
  medium: compareCardItemImageFormat;
  thumbnail: compareCardItemImageFormat;
}

export type compareCardItemImage = {
  id: number;
  width: number;
  height: number;
  caption: string;
  alternativeText: string;
  formats: compareCardItemImageFormatTypes;
}

export type compareCardItemImages = {
  id: number;
  Image: compareCardItemImage;
}

export type compareSection = {
  blockColor: string;
  Slug: string;
  Price: string;
  SeeAlsoTitle: string;
  BuyButtonText: string;
  compareBox: {
    title: string;
    hasNanny: boolean;
    hasNannyText: string;
    buttonLink: string;
    buttonText: string;
    description: compareSectionDescription[];
    buttonDescriptionText: string;
    image: {
      url: string;
    };
  };
  CardItemImages: compareCardItemImages[];
  compareList: compareListItem[];
  compareVideoTitle: string;
  Parameters: any[];
  compareVideoItem: compareVidoItem[];
};

export type compareDataType = {
  title: string;
  description: string;
  compareSection: compareSection[];
};

type shopListType = {
  title: string;
  Slug: string;
  hasNanny: boolean;
  hasNannyText: string;
  shopFeatList: { featText: string }[];
  shopButtons: {
    text: string;
    link: string;
    backgroundColor: string;
    icon: { url: string };
  }[];
  image: {
    url: string;
  };
};

export type shopDataType = {
  title: string;
  description: string;
  pageTextContent: string;
  shopList: shopListType[];
};

export type usefulItem = {
  title: string;
  content: string;
};

export type usefulDataType = {
  title: string;
  description: string;
  pageTextContent: string;
  usefulItems: usefulItem[];
};

const useFetchData = ({ path }: { path: pathType }) => {
  const [lang] = useLocalStorageState('lang');

  const [data, setData] = useLocalStorageState<object | null>(path, {
    defaultValue: null,
  });

  useEffect(() => {
    if (lang) {
      const dataFetch = async () => {
        const data = await (
          await fetch(`${baseUrl}${path}?pLevel=10&locale=${lang}`)
        ).json();
        // console.log(data.data);
        if (data.data && !isEqual(data.data, data)) {
          setData(data.data);
        }
      };

      dataFetch();
    }
  }, [data, lang, path, setData]);

  return data;
};

interface Language {
  id: number;
  name: string;
  code: string;
  createdAt: string;
  updatedAt: string;
  isDefault: boolean;
}

const getLocales = async () => {
  const response = await fetch(`${baseUrl}i18n/locales?sort=code`);
  const jsonData = await response.json();

  jsonData.sort((a: Language, b: Language) => a.code.localeCompare(b.code));

  return jsonData || [];
};

export { useFetchData, getLocales };
