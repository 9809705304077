import styled from 'styled-components'



export const Container = styled.div`
position: relative;
padding: 1em;
background: rgba(209, 225, 146, 0.3);
border-radius: 9% 52% 55% 23% / 100% 62% 100% 55% ;
margin-bottom: 1rem;
`

export const Icon = styled.div`
position: relative;
width: 3rem;

svg {
  position: relative;
  width: 100%;
  height: auto;
}
`

