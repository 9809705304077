import { compareSectionDescription, compareSectionDescriptionText } from 'api/api';
import ReactMarkdown from 'react-markdown';
import transformImageUri from 'utils/transformImageUri';


// export type ModalTextProps = {
//     text?: compareSectionDescription[];
//     type: string;
//     url: string;
//     children: ModalTextProps[]
// }

// type ModalTextParagraphProps = {
//     paragraph?: compareSectionDescriptionText[]
// }

// type ModalHeadingParagraphProps = {
//     paragraph?: compareSectionDescriptionText[],
//     level: number
// }

type RichText = {
    text: compareSectionDescription[]
}

// type RichTextType = {
//     type: string;
//     level: number;
//     url: string;
//     children: RichTextProps[];
// }

// type RichTextProps = {
//     type: string;
//     level: number;
//     text: string;
//     url: string;
//     children: RichTextProps[];
// }

type RichTextParagraphProps = {
    paragraph?: compareSectionDescriptionText[];
}
type RichTextHeadinghProps = {
    paragraph?: compareSectionDescriptionText[];
    level: number;
}


const Paragraph = ({ paragraph }: RichTextParagraphProps) => {
    return (
        <p>
            {
                paragraph?.map((el, i) => {
                    if (el.type == 'text')
                        return <ReactMarkdown
                            key={i}
                            components={{
                                p: ({ children }) => <>{children}</>,
                            }}
                            unwrapDisallowed={true}
                            transformImageUri={transformImageUri}
                            children={el.text || ''}
                        />
                    if (el.type == 'link') {
                        return (
                            <a href={el.url} key={i}>{el.children[0].text}</a>
                        )
                    }
                })
            }
        </p>
    )
}

const Heading = ({ level, paragraph }: RichTextHeadinghProps) => {
    const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;
    return (
        <HeadingTag>
            {
                paragraph?.map((el, i) => {
                    if (el.type == 'text')
                        return el.text;
                })
            }
        </HeadingTag>
    )
};

const RichText = ({ text }: RichText) => {
    return (
        <>
            {
                text?.map((el, i) => {
                    if (el.type == 'paragraph') {
                        return (
                            <Paragraph key={i} paragraph={el.children} />
                        )
                    }
                    if (el.type == 'heading') {
                        return (
                            <Heading key={i} level={el.level} paragraph={el.children} />
                        )
                    }
                })
            }
        </>
    )
}

export function getRichText(text: compareSectionDescription[]) {
    return <RichText text={text} />;
}


export default RichText;