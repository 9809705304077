import * as s from './Styles'
import Accordeon from 'components/Accordeon/Accordeon';
import { accordeonItem, useFetchData, usefulDataType } from 'api/api';
import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import transformImageUri from 'utils/transformImageUri';

function range(start: number, stop: number) {
  var array = [];

  var length = stop - start;

  for (var i = 0; i <= length; i++) {
    array[i] = start;
    start++;
  }

  return array;
}


const Useful = () => {
  const usefulData = useFetchData({
    path: 'useful-page-content',
  }) as usefulDataType;

  const { title, description, usefulItems } = usefulData || {};

  let currentPageNumbers = Math.ceil(usefulItems ? usefulItems.length / 15 : 0);

  const [usefulItemsState, setUsefulItemsState] = useState<accordeonItem[]>([]);
  const [usePaginationState, setPaginationState] = useState(currentPageNumbers);
  const [currentPage, setCurrentPage] = useState(1);
  var pages = range(1, usePaginationState);

  useEffect(() => {

    let num_per_page = 15;
    let offset_start = (currentPage - 1);
    if (offset_start > 0) {
      offset_start = (currentPage * num_per_page) - num_per_page;
    }
    if (offset_start < 0) {
      offset_start = 0;
    }

    let offset_end = (currentPage * num_per_page);

    if (currentPage == 1) {
      offset_end = num_per_page;
    }

    // console.log('offset_start: ' + offset_start);
    // console.log('offset_end: ' + offset_end);
    // let slicedItems = usefulItems;
    var slicedItems = usefulItems;

    if (usefulItems)
      slicedItems = usefulItems.slice(offset_start, offset_end);

    if (slicedItems?.length) {
      let arr: accordeonItem[] = [];

      slicedItems.forEach((x) => {
        arr.push({
          active: false,
          title: x.title,
          content: (
            <ReactMarkdown
              transformImageUri={transformImageUri}
              children={x.content || ''}
            />
          ),
        });
      });
      setUsefulItemsState(arr);
    }
  }, [usefulItems, currentPage]);


  return usefulData ? (
    <s.Useful>
      <div className='wrap'>
        <h1>
          {title}
          <small>{description}</small>
        </h1>
        <hr />


        <div className="pagination-cont">
          <div className="prev-page-item" onClick={() => setCurrentPage(((currentPage - 1) > 1) ? currentPage - 1 : 1)}><span>{"<=="}</span></div>
          {
            pages?.map((el, i) => {
              return (
                <div key={i} className={"page-item" + ((currentPage == el) ? " active" : "")} onClick={() => setCurrentPage(el)}><span>{el}</span></div>
              )
            })
          }
          <div className="next-page-item" onClick={() => setCurrentPage(((currentPage + 1) < currentPageNumbers) ? currentPage + 1 : currentPageNumbers)}>{"==>"}</div>
        </div>

        <Accordeon data={usefulItemsState} />
        <br />
        <hr />

        <div className="pagination-cont">
          <div className="prev-page-item" onClick={() => setCurrentPage(((currentPage - 1) > 1) ? currentPage - 1 : 1)}>{"<=="}</div>
          {
            pages?.map((el, i) => {
              return (
                <div key={i} className={"page-item" + ((currentPage == el) ? " active" : "")} onClick={() => setCurrentPage(el)}><span>{el}</span></div>
              )
            })
          }
          <div className="next-page-item" onClick={() => setCurrentPage(((currentPage + 1) < currentPageNumbers) ? currentPage + 1 : currentPageNumbers)}><span>{"==>"}</span></div>
        </div>


      </div>
    </s.Useful>
  ) : null;
};

export default Useful;
