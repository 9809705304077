import styled, { css } from 'styled-components'
import greenBg from 'assets/img/buyCardBg.svg'


type CardProps = {
  green?: boolean
  onClick?: React.MouseEventHandler
}


export const CardBox = styled.div<CardProps>`
position: relative;
text-align: center;
/* max-width: 250px; */
cursor: default;



&.item-appear {
  
}
&.item-appear-done {
  
}


.head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .3em;
  margin-bottom: 0.5em;
  font-family: 'baloo';
  font-weight: 800;
  font-size: 40px;
  color: ${({ theme }) => theme.colors.sea};
  height: 50px;

  img {
    position: relative;
    width: auto;
    height: 100%;
    transition: transform 500ms;
  }
}
.text {
    font-size: 20px;
    line-height: 140%;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: scale(1.2, 1.5);
  }

${({ green }) =>
    green && css`
    display: flex;
    flex-flow: column;
    align-items: center;
    &:before {
    background-image: url(${greenBg});
    background-position: 50% 50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    }
   .head {
     color: #B5BD95;
   }
  `
  }

  &:hover {
    .head {
      img {
        transform: scale(1.2) rotate(-5deg);
      }
    }
  }

`

