import { getLocales } from 'api/api';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';

export type locale = {
  id: number;
  name: string;
  code: string;
};

const useLang = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const LangFromUrl = searchParams.get('lang');

  const [currentLang, setLang] = useLocalStorageState('lang', {
    defaultValue: '',
  });

  const [locales, setLocales] = useLocalStorageState<null | any[]>('locales', {
    defaultValue: null,
  });

  useEffect(() => {
    getLocales().then((res) => {
      setLocales(res);
    });
  }, [setLocales]);

  useEffect(() => {
    try {
      const navigatorLangCode = navigator.language.split('-')[0];

      console.log('navigatorLangCode: ' + navigatorLangCode);
      if (!currentLang && locales !== null) {
        if (
          navigatorLangCode &&
          locales?.some((x: locale) => x.code === navigatorLangCode)
        ) {
          setLang(navigatorLangCode);
        } else {
          console.log('setting default');
          setLang('en');
        }
      }
    } catch (error) {
      console.log(error);
      setLang('en');
    }
  }, [currentLang, locales, setLang]);

  useEffect(() => {
    if (LangFromUrl) {
      searchParams.delete('lang');
      setSearchParams(searchParams);
      setLang(LangFromUrl);
    }
  }, [LangFromUrl, searchParams, setLang, setSearchParams]);
};

export default useLang;
