import List, { listDataType } from 'components/List/List';
import Nanny from 'components/Nanny/Nanny';
import { ReactElement } from 'react';
import * as s from './Styles';

type CardProps = {
  image: string;
  title: string;
  listContent: listDataType[];
  buttons: ReactElement | ReactElement[];
  hasNanny?: boolean;
  hasNannyText?: string;
  slug: string;
};

const BuyCard = ({
  image,
  listContent,
  buttons,
  hasNanny,
  title,
  hasNannyText,
  slug
}: CardProps) => {
  return (
    <s.BuyCardBox>

      {
        slug ?
          <a className={`greenBg ${hasNanny ? 'blue' : ''}`} href={'/shop/' + slug}>
            <div className='title'>{title}</div>{' '}
            <div style={{ fontSize: 14 }}>
              <List data={listContent} />
            </div>
            <div className='sideImage'>
              <img
                src={image}
                alt='leCoon'
              />
            </div>
            {hasNanny && hasNannyText && (
              <div className='nanny'>
                <Nanny text={hasNannyText} />
              </div>
            )}
          </a>
          :
          <div className={`greenBg ${hasNanny ? 'blue' : ''}`}>
            <div className='title'>{title}</div>{' '}
            <div style={{ fontSize: 14 }}>
              <List data={listContent} />
            </div>
            <div className='sideImage'>
              <img
                src={image}
                alt='leCoon'
              />
            </div>
            {hasNanny && hasNannyText && (
              <div className='nanny'>
                <Nanny text={hasNannyText} />
              </div>
            )}
          </div>
      }


      <div className='buttons'> {buttons}</div>
    </s.BuyCardBox >
  );
};

export default BuyCard;
