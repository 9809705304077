import styled from 'styled-components'


export const GoodCardInfo = styled.div`

// max-width: 50%;
padding-right: 0px;
width: 100%;

@media screen and (max-width: 721px) {


}

.params {
    .param {
        display: flex;
        margin-bottom: 30px;
        justify-content: space-between;
        .name {
            font-weight: bold;
        }
        .value {
            text-align: right;
            font-size: 15px;
        }
    }
}

`

export const GoodCard = styled.div`

display: grid;
grid-template-columns: 60% 40%;
margin-top: 5px;

@media screen and (max-width: 721px) {
    display: block;
}

h1 {
    font-size: max(3.7vw,35px);
    margin-bottom: 4vh;
    line-height: 110%;
    font-weight: 500;
    max-width: 800px; 
    margin-top: 10px;
}

@media (max-width:921px) {
    h1 {
        margin-bottom: 10px;
    }
}

.price
{
    font-size: max(2.7vw,25px);
    margin-bottom: 20px;
}

.text {
    margin-top: 30px;
    p {
        margin-bottom: 10px;
        line-height: 22px;
    }
}

`

export const GoodSlider = styled.div`

min-height: 650px;
// max-width: 50%;
padding-right: 0px;
margin-right: 30px;
position: relative;
padding-top: 100px;


@media (max-width:921px) {
    min-height: auto;
    padding-top: 0px;
    margin-right: 0px;
    &:before {
        left: 0px !important;
        display: none !important;
    }
}

&:before {
    content: " ";
    display:block;
    border-radius: 100% 37% 83% 100% / 100% 60% 73% 100%;
    background-color: #E5EDEF;
    width: 100%;
    height: 100%;
    left: -50px;
    top: 50px;
    position: absolute;

}

.videoTitle
{
    font-family: 'baloo';
    font-weight: 800;
    font-size: 40px;
    text-align: center;
    position: relative;
    z-index: 10;
    margin-bottom: 20px;
    margin-top: 30px;
}

.compareVideos
{
    display: flex;
    max-width: 90%;
    gap: 20px;
}



.sliders {
    display: flex;
    justify-content: flext-start;
    margin-bottom: 30px;
}

@media (max-width:921px) {
    .sliders {
        display: block;
    }

    .mySwiper2 {
        max-width: 100%;
        margin-bottom: 40px;
    }
}

.swiper img {
    border-radius: 30px;
}

.mySwiper2 {
    max-height: 500px;
    order: 1;
    max-width: 450px;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
    img {
        // max-width: 350px
        // height: 100%;
        width: auto;
    }

    .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .swiper-button-prev {
        background: white;
        width: 44px;
        border-radius: 30px;
        border: solid 2px rgb(236, 225, 18);
        &:after
        {
            color: rgb(236, 225, 18);
            font-weight: bold;
            font-size: 20px;
        }

    }
    .swiper-button-next {
        background: white;
        width: 44px;
        border-radius: 30px;
        border: solid 2px rgb(236, 225, 18);
        &:after
        {
            color: rgb(236, 225, 18);
            font-weight: bold;
            font-size: 20px;
        }
    }
}

.swiper-thumbs, .mySwiper
{
    max-height: 500px;
    max-width: 80px;
    order: 0;
    margin-right: 20px;
    margin-left: 0px;
    padding-top: 10px;
    margin-left: -20px;

    img {
        max-width: 80px;
        height: 80px;
    }

    .swiper-slide {
        border-radius: 30px;
        cursor: pointer;
        min-width: 80px;
        display: flex;
        justify-content: center;
        position: relative;

        img {
            position:relative;
            z-index: 2;
        }

        &:hover {
            &:before {
                position: absolute;
                z-index: 1;
                top: -5px;
                left: 6px;
                width: calc( 76% + 5px );
                height: calc( 82% + 10px );
                content: ' ';
                display: block;
                background-color: rgba(236, 225, 18,0.2);
                border-radius: 27% 76% 52% 100% / 72% 60% 100% 42%;
        }
        }
    }

    .swiper-slide-thumb-active {
        &:before {
            position: absolute;
            z-index: 1;
            top: -5px;
            left: 6px;
            width: calc( 76% + 5px );
            height: calc( 82% + 10px );
            content: ' ';
            display: block;
            background-color: rgb(236, 225, 18);
            border-radius: 27% 76% 52% 100% / 72% 60% 100% 42%;
        }
    }
}

@media (max-width:921px) {

    .swiper-thumbs, .mySwiper
    {
        max-width: none;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

`

export const GoodPriceSection = styled.div`

    position:relative;
    margin-top: 50px;
    width: 100%;

    @media (max-width:921px) {
        display:flex;
        justify-content: center;
        margin-top: 0px !important;
    }

    .price-section {
        padding-top: 20px;
        padding-bottom: 20px;
        // width: 100%;
        text-align: center;
        max-width: 100%;
        margin: 0 auto;
        background: white;
        // box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.1);
        border-radius: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        .price {
            margin-right: 20px;
        }
        a {
            margin-top: -15px;
        }
    }


`

export const GoodSeeAlso = styled.div`

margin: 0 auto;


@media (max-width:921px) {
    margin-bottom: 50px;
}

.good-see-also
{
    position: relative;
    .title
    {
        font-family: 'baloo';
        font-weight: 800;
        font-size: 40px;
        text-align: center;
        position: relative;
        z-index: 10;
        margin-bottom: 20px;
        margin-top: 30px;
    }
    .good-see-also-cont {
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .good-see-also-cont.one-item
    {
        justify-content: center;
    }

    .good-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        img {
            max-width: 40%;
        }
    }

}

`