import styled from 'styled-components';
import videoCardBg from 'assets/img/videoCardBg.svg';

type PosterProps = {
  image: string;
};

export const Poster = styled.div<PosterProps>`
  position: relative;
  height: auto;
  width: 100%;
  padding-top: 57%;
  margin-top: 10px;
  overflow: hidden;
  border-radius: 30px;
  background-image: url(${({ image }) => image});
  background-size: 100% auto;
  background-repeat: no-repeat;
  transition: all 500ms;

  svg {
    position: absolute;
    right: 8px;
    bottom: 8px;
    width: 40px;
    height: 40px;
    color: #fff;
    transform-origin: 100% 100%;
    transition: transform 200ms;
  }
`;

type CardProps = {
  green?: boolean;
};

export const CardBox = styled.div<CardProps>`
  position: relative;
  text-align: left;
  cursor: default;
  width: 100%;
  user-select: none;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(${videoCardBg});
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    transform: scale(1.2) translateY(10%);
  }

  .head {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-end;

    .is {
      font-family: 'baloo';
      color: #8b5a4e;
      line-height: 100%;
      transform: translateY(-0.1em);
      padding-right: 0.2em;
    }

    .titleBox {
      flex: 1;
    }

    .title {
      font-family: 'baloo';
      font-size: 40px;
      color: ${({ theme }) => theme.colors.orange};
      line-height: 100%;
      transform-origin: 0 0;
      transition: transform 200ms;
    }

    .tap {
      color: #8f6b63;
      font-size: 12px;
      line-height: 100%;
      position: relative;
      padding-right: 18px;

      svg {
        position: absolute;
        right: 0;
        bottom: -15px;
      }
    }
  }

  &:hover {
    .head {
      .title {
        transform: scale(1.15);
      }
    }
    ${Poster} {
      background-size: 120% auto;
      transition: all 2000ms;
      svg {
        transform: scale(1.2);
        color: ${({ theme }) => theme.colors.yellow};
      }
    }
  }
`;

export const Logo = styled.div`
  position: relative;
  width: auto;
  height: 20px;
  transform: translate(3px, 7px);
`;
