import styled from 'styled-components';
import headerBg from 'assets/img/headerBg.webp';
import clouds from 'assets/img/clouds.svg';
import topMenuBg from 'assets/img/topMenuBg.svg';
import speechBg from 'assets/img/speech.svg';
import speechBgMob from 'assets/img/speech-mobile.svg';
import leCoon from 'assets/img/enot.webp';

export const HeaderBox = styled.div<{ $small?: boolean }>`
  width: 100vw;
  position: relative;
  z-index: 1;
  background-color: #edefee;
  background-image: url(${headerBg});
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${({ $small }) => ($small ? '45vh' : '60vh')};
  user-select: none;
  margin-bottom: 50px;
  min-height: 300px;
  @media (max-height: 600px), (max-width: 850px) {
    height: 40vh;
    margin-bottom: ${({ $small }) => ($small ? '20px' : '70px')};
    min-height: auto;
    max-height: 120px;
  }
`;

export const Clouds = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 0;
  height: 25%;
  width: 100%;
  background-image: url(${clouds}), url(${clouds});
  background-position: 0 50%, 1px 50%;
  background-repeat: repeat-x;
  background-size: auto 100%;
  pointer-events: none;
  transform: translateZ(0) translateY(51%);
  filter: blur(0);
`;

export const Logo = styled.svg<{ $small?: boolean }>`
  position: absolute;
  z-index: 5;
  left: 50%;
  top: ${({ $small }) => ($small ? '28%' : '22%')};
  transform: translateX(-50%);
  height: 13%;
  min-height: 40px;
  width: auto;
  @media (max-height: 600px), (max-width: 850px) {
    display: none;
  }
`;
export const EnotBox = styled.div`
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 7%);
  height: 65%;
  width: 100%;
  /* aspect-ratio: 3/1; */
  pointer-events: none;
  background-image: url(${leCoon});
  background-position: 50% 0;
  background-size: contain;
  background-repeat: no-repeat;
`;

// export const LeCoonImg = styled.img`
//   position: relative;
//   width: auto;
//   height: 100%;
// `;
export const PromoPlay = styled.svg`
  width: auto;
  height: 100%;
  position: relative;
  transition: all 1600ms;
  color: ${({ theme }) => theme.colors.pink};
  transform-origin: 55% 55%;
`;

export const PromoVideo = styled.div`
  position: absolute;
  z-index: 1;
  background: rgba(255, 255, 255, 0.71);
  backdrop-filter: blur(10px);
  border-radius: 70px;
  top: 55%;
  left: 40%;
  transform: translateX(-120%);
  padding: 0.4em;
  padding-right: 0.8em;
  font-family: 'ayuthaya';
  text-transform: uppercase;
  font-size: 36px;
  line-height: 100%;
  display: flex;
  align-items: center;
  gap: 0.4em;
  cursor: pointer;
  transition: all 200ms;

  small {
    display: block;
    font-size: 0.43em;
    line-height: 100%;
  }

  &:hover {
    background-color: #fff;
    ${PromoPlay} {
      color: ${({ theme }) => theme.colors.orange};
      transform: rotate(720deg);
    }
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

export const BuyBtnBox = styled.div`
  position: absolute !important;
  left: 50%;
  top: 70%;
  transform: translate(50%, 0);
  z-index: 20;
  padding-left: 3em;
  @media (max-width: 850px) {
    display: none;
  }
  a {
    text-decoration: none;
  }
  
`;

export const Outer = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;
  height: 100%;
`;

export const TopMenuBox = styled.div`
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 3.5%;
  transform: translateX(-50%);

  &::before {
    content: '';
    position: absolute;
    left: -200px;
    bottom: -30px;
    width: 150%;
    height: 300px;
    background-image: url(${topMenuBg});
    background-position: 50% 100%;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  @media (max-height: 600px), (max-width: 850px) {
    display: none;
  }
`;
export const Parallax = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  @media (max-height: 600px), (max-width: 1000px) {
    display: none;
  }

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .arrowDecor {
    position: absolute;
    bottom: 0%;
    right: 0%;
    z-index: 11;
    pointer-events: none;
    will-change: all;

    svg {
      width: 25vw;
      height: auto;
    }
  }

  .triangleDecor {
    position: absolute;
    bottom: 3%;
    left: 3%;
    z-index: 11;
    pointer-events: none;
    will-change: all;

    svg {
      width: 10vw;
      height: auto;
    }
  }

  .sunDecor {
    position: absolute;
    top: 1%;
    left: 4%;
    z-index: 11;
    pointer-events: none;
    will-change: all;
    color: ${({ theme }) => theme.colors.yellow};

    svg {
      width: 7vw;
      max-width: 120px;
      height: auto;
    }
  }

  .orangeDecor {
    position: absolute;
    top: 6%;
    right: 0%;
    z-index: 11;
    pointer-events: none;
    will-change: all;

    svg {
      width: 10vw;
      max-width: 150px;
      height: auto;
    }
  }
`;

export const SpeechBox = styled.div`
  position: absolute;
  cursor: default;
  width: 400px;
  height: 120px;
  left: 50%;
  top: 71%;
  transform: translateX(-80%);
  z-index: 12;
  background-image: url(${speechBg});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #fff;
  padding-bottom: 16px;
  font-family: 'baloo';
  font-weight: 800;
  font-size: 40px;
  line-height: 100%;
  @media (max-height: 600px), (max-width: 850px) {
    transform: translateX(-50%);
    top: 76%;
    background-image: url(${speechBgMob});
  }
`;
