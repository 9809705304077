import { ReactNode } from 'react';
import * as s from './Styles';

type CardProps = {
  iconImg?: any;
  className?: string;
  text?: string | ReactNode;
  title?: string;
  green?: boolean;
  onClick?: React.MouseEventHandler;
};

const Card = ({
  iconImg,
  className,
  text,
  title,
  green,
  onClick,
}: CardProps) => {
  return (
    <s.CardBox
      onClick={onClick}
      green={green}
      className={className}>
      <div className='head'>
        {iconImg && !green && (
          <img
            src={iconImg}
            alt={title}
          />
        )}
        {title}
      </div>
      <div className='text'>{text}</div>
    </s.CardBox>
  );
};

export default Card;
