import React, { ReactNode } from 'react';
import * as s from './Styles'
import { useState } from 'react'



interface dataProp {
	active: boolean;
	title: string;
	content: ReactNode;
}

const Accordeon = ({ data }: { data: dataProp[] }) => {


	const [activeItem, setActiveItem] = useState<string | null>(null)

	function checkIsActive(itemName: string, active: boolean) {
		if (activeItem == null && active == true) {
			return true;
		}
		if (activeItem === itemName) {
			return true
		} else {
			return false
		}
	}

	function clickHandler(itemName: string) {
		if (checkIsActive(itemName, false)) {
			setActiveItem(null)
		} else {
			setActiveItem(itemName)
		}
	}


	const drawItem = data.map((item) => {
		return (
			<s.Item isActive={checkIsActive(item.title, item.active)} key={item.title}>
				<s.Head onClick={() => clickHandler(item.title)}>{item.title}</s.Head>
				<s.Content>{item.content}</s.Content>
			</s.Item >
		)
	})


	return (
		<div>{drawItem}</div>
	)
}

export default Accordeon