import styled from 'styled-components'

export const VideoBox = styled.div`
position: relative;
width: 100%;
padding-top: 56%;
border-radius: 20px;

iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}
`

export const VideoGrid = styled.div`
position: relative;
display: grid;
width: 100%;
grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
gap: 3rem;
margin-bottom: 3rem;
`
