import * as s from './Styles';
import { useContext } from 'react';
import { ModalContext } from 'App';
import { VideoModal } from 'pages/MainPage/Styles';

import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from 'components/Button/Button';
import { ListWithoutStrike } from 'components/List/List';
import VideoCard from 'components/VideoCard/VideoCard';
import { getRichText } from 'components/RichText/RichText';

import { accordeonItem, compareDataType, useFetchData, compareCardItemImages, compareVidoItem } from 'api/api';
import Accordeon from 'components/Accordeon/Accordeon';
import Color from 'color';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

const Good = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [usefulItemsState, setUsefulItemsState] = useState<accordeonItem[]>([]);

    const compareData = useFetchData({
        path: 'compare',
    }) as compareDataType;

    const { title, description, compareSection } = compareData || {};

    var current_address = window.location.pathname.split('/');
    current_address = current_address.filter((n) => n !== '');
    var current_slug = current_address[current_address.length - 1];
    var page_exists = false;


    for (let good of compareSection) {
        if (good.Slug == current_slug) {
            page_exists = true;
        }
    }

    if (!page_exists) {
        window.location.href = "/shop";
    }

    var images: compareCardItemImages[] = [];
    var compareListData: any[] = [];
    var videos: compareVidoItem[] = [];
    var compareVideoTitle: string = '';
    var description_text_block: JSX.Element;
    var description_parameters: JSX.Element;
    var good_title: string = '';
    var good_price: string = '';
    var buy_button: string = '';
    var buy_button_text: string = '';
    var see_also_title: string = '';
    var see_also_items: any[] = [];

    compareSection.map((x, i) => {
        if (x.Slug == current_slug) {
            images = x.CardItemImages;
            videos = x.compareVideoItem;
            compareVideoTitle = x.compareVideoTitle;
            description_text_block = getRichText(x.compareBox.description);
            description_parameters = getParameters(x.Parameters);
            good_title = x.compareBox.title;
            good_price = x.Price;
            buy_button = x.compareBox.buttonLink;
            see_also_title = x.SeeAlsoTitle;
            buy_button_text = x.BuyButtonText;

        }
    });

    compareSection.map((x, i) => {
        if (x.Slug !== current_slug) {
            see_also_items.push(x);
        }
    });

    const modalControl = useContext(ModalContext);

    function openVideoInModal(innerContent: JSX.Element) {
        const data = {
            title: 'MobileMenu',
            body: <VideoModal>{innerContent}</VideoModal>,
        };
        modalControl?.update(data);
    }

    function getParameters(params: any[]) {
        return (
            <div className="params">
                {
                    params.map((x, i) => {

                        return (
                            <div className="param" key={i}>
                                <div className="name">{x.Name}</div>
                                <div className="value">{x.Info}</div>
                            </div>
                        )

                    })
                }
            </div>
        );
    }

    var effect_updated = false;

    useEffect(() => {

        if (!effect_updated) {

            let slicedItems = usefulItemsState;

            if (description_parameters.props.children.length > 0) {
                slicedItems[0] = {
                    'active': true,
                    'title': 'Характеристики',
                    'content': description_parameters
                };
            }


            if (description_text_block) {
                let not_to_render = false;
                if (description_text_block.props.text == null || (description_text_block.props.text.length == 1 && description_text_block.props.text[0].children[0]['text'] == '')) {
                    not_to_render = true;
                }
                if (!not_to_render) {
                    slicedItems[1] = {
                        'active': false,
                        'title': 'Описание',
                        'content': description_text_block
                    };
                }
            }

            effect_updated = true;
        }

    }, [usefulItemsState]);


    return (
        <div className="wrap">

            <s.GoodCard>
                <div>

                    {
                        (window.matchMedia("(max-width: 921px)").matches) ?
                            <>
                                <h1>{good_title}</h1>

                                <s.GoodPriceSection>

                                    <div className="price-section">
                                        <div className="price">{good_price}</div>
                                        <a
                                            target='_blank'
                                            href={buy_button}
                                            rel='noreferrer'>
                                            <Button
                                                text={buy_button_text}
                                                style={{
                                                    background: "blue",
                                                    color: '#fff',
                                                    textShadow: 'none',
                                                }}
                                            />
                                        </a>
                                    </div>

                                </s.GoodPriceSection>
                            </>
                            :
                            ""
                    }

                    <s.GoodSlider>
                        <div className="sliders">

                            <Swiper
                                style={{
                                    '--swiper-navigation-color': '#fff',
                                    '--swiper-pagination-color': '#fff',
                                }}
                                spaceBetween={10}
                                navigation={true}
                                thumbs={{ swiper: thumbsSwiper }}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="mySwiper2"
                            >
                                {
                                    images?.map((image, image_index) => {
                                        if (image.Image !== null) {
                                            return (<SwiperSlide key={image_index}>
                                                <img src={process.env.REACT_APP_APIHOST + image.Image.formats.small.url} alt={image.Image.alternativeText} />
                                            </SwiperSlide>)
                                        }
                                    })
                                }

                            </Swiper>


                            <Swiper
                                onSwiper={setThumbsSwiper}
                                spaceBetween={10}
                                navigation={false}
                                direction={window.matchMedia("(max-width: 921px)").matches ? "horizontal" : "vertical"}
                                slidesPerView={4}
                                freeMode={false}
                                watchSlidesProgress={true}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="mySwiper"
                            >
                                {
                                    images?.map((image, image_index) => {
                                        if (image.Image !== null) {
                                            return (<SwiperSlide key={image_index}>
                                                <img src={image.Image.formats.large ? process.env.REACT_APP_APIHOST + image.Image.formats.large.url : process.env.REACT_APP_APIHOST + image.Image.formats.thumbnail.url} alt={image.Image.alternativeText} />
                                            </SwiperSlide>)
                                        }
                                    })
                                }
                            </Swiper>


                        </div>
                        <br />
                        <br />

                        {
                            compareSection.map((x, i) => {
                                if (x.Slug == current_slug) {

                                    let colorText = x.blockColor
                                        ? Color(x.blockColor).saturate(0.1).darken(0.3)
                                        : '#000';

                                    let compareListData: any[] = [];

                                    x.compareList.forEach((item) => {
                                        compareListData.push({
                                            text: item.text,
                                            iconColor: colorText,
                                            negative: item.isStrike ? true : false,
                                        });
                                    });

                                    return <ListWithoutStrike data={compareListData} key={i} />
                                }
                            })
                        }

                        {videos.length > 0 ? <div className="videoTitle">{compareVideoTitle}</div> : ""}

                        <div className='compareVideos'>
                            {
                                videos.map((video_item, video_index) => {
                                    var poster_url = process.env.REACT_APP_APIHOST + video_item.poster.url;
                                    return <VideoCard
                                        key={video_index}
                                        isSimple
                                        onClick={() =>
                                            openVideoInModal(
                                                <iframe
                                                    width='560'
                                                    height='315'
                                                    src={video_item.link}
                                                    title={compareVideoTitle}
                                                    frameBorder='0'
                                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                    allowFullScreen
                                                />
                                            )
                                        }
                                        videoPoster={
                                            poster_url
                                        }
                                        title={compareVideoTitle}
                                    />
                                })
                            }
                        </div>

                    </s.GoodSlider>
                </div>

                {
                    (window.matchMedia("(min-width: 921px)").matches) ?

                        <s.GoodCardInfo>

                            <h1>{good_title}</h1>

                            <s.GoodPriceSection>

                                <div className="price-section">
                                    <div className="price">{good_price}</div>
                                    <a
                                        target='_blank'
                                        href={buy_button}
                                        rel='noreferrer'>
                                        <Button
                                            text={buy_button_text}
                                            style={{
                                                background: "blue",
                                                color: '#fff',
                                                textShadow: 'none',
                                            }}
                                        />
                                    </a>
                                </div>

                            </s.GoodPriceSection>


                            <Accordeon data={usefulItemsState} />

                        </s.GoodCardInfo >
                        : ""

                }

            </s.GoodCard>

            <br />
            <br />
            <br />
            <hr />
            <br />

            <s.GoodSeeAlso>
                <div className="good-see-also">

                    <div className="title">{see_also_title}</div>

                    <div className={see_also_items.length == 1 ? "good-see-also-cont one-item" : 'good-see-also-cont'}>

                        {see_also_items.map((x, i) => {
                            return (
                                <a className="good-item" key={i} href={"/shop/" + x.Slug}>
                                    <div className='title'>{x.compareBox.title}</div>

                                    <img
                                        className='pic'
                                        src={process.env.REACT_APP_APIHOST + x.compareBox?.image?.url}
                                        alt={x.compareBox.title} />
                                </a>
                            );
                        })}

                    </div>

                </div>

            </s.GoodSeeAlso>

        </div>


    )

};
export default Good;
