import styled from 'styled-components';
import greenBg from 'assets/img/buyCardBg.svg';
import blueBg from 'assets/img/buyCardBgBlue.svg';

type CardProps = {
  green?: boolean;
  onClick?: React.MouseEventHandler;
};

export const BuyCardBox = styled.div<CardProps>`
  position: relative;

  .greenBg {
    background-image: url(${greenBg});
    background-position: 50% 50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    padding: 3em;
    padding-left: 30%;
    display: flex;
    flex-flow: column;
    gap: 2em;
    .title {
      color: black;
    }
    ul li, ul li span {
      color: black;
    }
    &.blue {
      background-image: url(${blueBg});
    }

    .nanny {
      position: absolute;
      width: 130px;
      height: auto;
      top: 0;
      right: 0;

      svg {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: 50% 50%;
      }
    }

    .sideImage {
      position: absolute;
      right: 65%;
      top: -5%;
      width: 50%;
      height: 110%;

      img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: 50% 50%;
      }
    }

    .title {
      font-family: 'baloo';
      font-weight: 800;
      font-size: 40px;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 1em;
    margin-top: 1em;
  }
`;
