import * as s from './Styles';
import { ReactComponent as FooterSun } from 'assets/img/footer_sun.svg';
import { ReactComponent as Bird } from 'assets/img/bird.svg';
import Button from 'components/Button/Button';
import { ReactComponent as Whatsapp } from 'assets/img/whatsapp.svg';
import { footerContentType } from 'api/api';

type FooterProps = {
  className?: string;
  data: footerContentType;
};

const Footer = ({ className, data }: FooterProps) => {
  const { whatsAppLink, contactEmail, phone, workingHours, description } = data;

  return (
    <s.FooterBox
      id='contacts'
      className={className}>
      <FooterSun className='footerSun' />
      <Bird className='bird' />
      <div className='wrap'>
        <a
          style={{ display: 'inline-block', marginBottom: '1em' }}
          href={whatsAppLink}>
          <Button
            style={{ background: '#fff' }}
            icon={<Whatsapp />}
            text='WhatsApp'
          />
        </a>
        <div className='bigText'>{contactEmail}</div>
        <p>{phone}</p>
        <p>{workingHours}</p>
        <p>{description}</p>
      </div>
    </s.FooterBox>
  );
};

export default Footer;
