import { ListBox } from './Styles';
import { ReactComponent as Check } from 'assets/img/check.svg';
import { ReactComponent as No } from 'assets/img/no.svg';

export type listDataType = {
  text: string;
  negative?: boolean;
  iconColor: string;
};

const List = ({ data }: { data: listDataType[] }) => {
  return (
    <ListBox>
      <ul>
        {data.map((x, i) => {
          return (
            <li key={i}>
              {x.negative ? <No /> : <Check style={{ color: x.iconColor }} />}
              <span className={`${x.negative ? 'overline' : ''}`}>
                {x.text}
              </span>
            </li>
          );
        })}
      </ul>
    </ListBox>
  );
};

export const ListWithoutStrike = ({ data }: { data: listDataType[] }) => {
  return (
    <ListBox>
      <ul>
        {data.map((x, i) => {
          if (!x.negative) {
            return (
              <li key={i}>
                <Check style={{ color: x.iconColor }} />
                <span>
                  {x.text}
                </span>
              </li>
            );
          }
        })}
      </ul>
    </ListBox>
  );
};

export default List;
