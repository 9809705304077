import * as s from './Styles'

type ButtonProps = {
	icon?: React.ReactNode
	style?: React.CSSProperties
	className?: string
	children?: React.ReactNode
}


const Alert = ({ icon, style, className, children }: ButtonProps) => {
	return (
		<s.Container className={className} style={style}>
			<s.Icon>{icon}</s.Icon>
			{children}
		</s.Container >
	)
}

export default Alert