import React, { Suspense, useEffect, useState } from 'react';
import { ReactComponent as Cart } from 'assets/img/cart.svg';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { ReactComponent as PromoPlay } from 'assets/img/promoVideoPlay.svg';
import Button from 'components/Button/Button';
import * as s from './Styles';
import { useNavigate } from 'react-router-dom';
import { VideoModal } from 'pages/MainPage/Styles';
import { ModalContext } from 'App';
import { useContext } from 'react';
import { footerHeaderType } from 'api/api';
// import SpeechText from './SpeechText';
// import HeaderParallax from './HeaderParallax';
import TopMenu from 'components/TopMenu/TopMenu';
const SpeechText = React.lazy(() => import('./SpeechText'));
const HeaderParallax = React.lazy(() => import('./HeaderParallax'));


const Header = ({
  $small,
  data,
}: {
  $small?: boolean;
  data: footerHeaderType;
}) => {
  const modalControl = useContext(ModalContext);
  function openVideoInModal(video_link = '') {
    // console.log(data);
    if (video_link == '')
      video_link = 'https://www.youtube.com/embed/-TNrpwI76Os';

    const data = {
      title: 'MobileMenu',
      body: (
        <VideoModal>
          <iframe
            width='560'
            height='315'
            src={video_link}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen></iframe>
        </VideoModal>
      ),
    };
    modalControl?.update(data);
  }

  const navigate = useNavigate();

  const {
    orderButton,
    LinkUrl,
    menu,
    typings,
    videoButton: { bigText, smallText },
    VideoLink
  } = data || { videoButton: {} };


  const { innerWidth } = window;

  const [showSpeech, setShowSpeech] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpeech(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <s.HeaderBox $small={$small}>
        <s.Outer>
          <s.TopMenuBox>
            <TopMenu data={menu} />
          </s.TopMenuBox>
        </s.Outer>
        <s.Logo
          $small={$small}
          as={Logo}
        />
        {!$small && (
          <>
            {
              LinkUrl ?
                <s.BuyBtnBox >
                  <a href={LinkUrl} target="_blank">
                    <Button
                      text={orderButton}
                      style={{ paddingLeft: '3em', marginLeft: '-1.5em' }}
                    />
                  </a>
                </s.BuyBtnBox>

                :

                <s.BuyBtnBox onClick={() => navigate(`delivery`)}>
                  <Button
                    icon={<Cart />}
                    text={orderButton}
                    style={{ paddingLeft: '3em', marginLeft: '-1.5em' }}
                  />
                </s.BuyBtnBox>
            }

            <s.PromoVideo onClick={() => openVideoInModal(VideoLink)}>
              <s.PromoPlay as={PromoPlay} />
              <div>
                {bigText} <small>{smallText}</small>
              </div>
            </s.PromoVideo>
          </>
        )}
        <s.EnotBox />
        {showSpeech && !$small && typings && (
          <Suspense fallback={<div></div>}>
            <SpeechText typings={typings} />
          </Suspense>
        )}
        <s.Clouds />
        {innerWidth > 1000 && (
          <Suspense fallback={<div></div>}>
            <HeaderParallax />
          </Suspense>
        )}
      </s.HeaderBox>
    </>
  );
};

export default Header;
