import * as s from './Styles';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { ReactComponent as TapArrow } from 'assets/img/tapArrow.svg';
import { ReactComponent as Play } from 'assets/img/promoVideoPlay.svg';
import { t } from 'i18next';

type CardProps = {
  videoPoster: any;
  className?: string;
  title?: string;
  green?: boolean;
  onClick?: React.MouseEventHandler;
  isSimple?: boolean;
  beforeTitle?: string;
  afterTitle?: string;
};

const VideoCard = ({
  videoPoster,
  className,
  title,
  green,
  onClick,
  isSimple,
  beforeTitle = '',
  afterTitle = '',
}: CardProps) => {
  return (
    <s.CardBox
      onClick={onClick}
      green={green}
      className={className}>
      {!isSimple && (
        <div className='head'>
          <div className='is'>{beforeTitle}</div>
          <div className='titleBox'>
            <s.Logo as={Logo} />
            <div className='title'>{title}</div>
          </div>
          <div className='tap'>
            {afterTitle} <TapArrow />
          </div>
        </div>
      )}

      <s.Poster image={videoPoster}>
        <Play />
      </s.Poster>
    </s.CardBox>
  );
};

export default VideoCard;
