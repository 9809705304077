import * as s from './Styles'
import { ReactComponent as NumberBg } from 'assets/img/sun-decor.svg'
import { ReactNode } from 'react'

type CardProps = {
	iconImage: any
	text: ReactNode
	number: string
	className?: string
	color: string

}


const StairsCard = ({ iconImage, className, text, number, color }: CardProps) => {
	return (
		<s.CardBox $color={color}>
			<img className='image' src={iconImage} alt={number} />
			<div className='text'>{text}</div>
			<div className='number'><span>{number}</span><NumberBg /></div>
		</s.CardBox >
	)
}

export default StairsCard