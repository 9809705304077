import Button from 'components/Button/Button';
import { ReactComponent as Arrow } from 'assets/img/arrow.svg';
import Accordeon from 'components/Accordeon/Accordeon';
import Alert from 'components/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { accordeonItem, libraryDataType, useFetchData } from 'api/api';
import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from 'react';
import transformImageUri from 'utils/transformImageUri';

const Library = () => {
  const libraryData = useFetchData({
    path: 'library-page-content',
  }) as libraryDataType;

  const {
    title,
    description,
    pageTextContent,
    coloredTextBubble,
    downloadSection,
    instructionSection,
  } = libraryData || {};

  const navigate = useNavigate();

  const [downloadItems, setDownloadItems] = useState<accordeonItem[]>([]);

  useEffect(() => {
    if (downloadSection?.downloadElements?.length) {
      let arr: accordeonItem[] = [];

      downloadSection.downloadElements.forEach((x) => {
        arr.push({
          active: false,
          title: x.title,
          content: <ReactMarkdown children={x.content || ''} />,
        });
      });

      setDownloadItems(arr);
    }
  }, [downloadSection]);

  return libraryData ? (
    <>
      <div className='wrap'>
        <h1>
          {title}
          <small>{description}</small>
        </h1>

        <ReactMarkdown
          transformImageUri={transformImageUri}
          children={pageTextContent || ''}
        />

        {coloredTextBubble.map((x, i) => {
          let style = x.color === 'red' ? { background: '#EBD7D7' } : {};

          return (
            <Alert
              style={style}
              key={i}
              icon={
                <img
                  src={process.env.REACT_APP_APIHOST + x.icon?.url}
                  alt={x.title}
                />
              }>
              <h3>{x.title}</h3>
              <ReactMarkdown
                transformImageUri={transformImageUri}
                children={x.bubbleText || ''}
              />
            </Alert>
          );
        })}

        <hr />

        <h2>
          {downloadSection.title}
          <small>{downloadSection.description}</small>
        </h2>

        <Accordeon data={downloadItems} />

        <hr />

        <h3>{instructionSection.title}</h3>

        <Button
          onClick={() => navigate(instructionSection.buttonLink)}
          icon={<Arrow />}
          text={instructionSection.buttonText}
          style={{ background: '#D9F2EE' }}
        />
      </div>
    </>
  ) : null;
};

export default Library;
